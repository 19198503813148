import React,{useEffect,useState} from "react";
import jQuery from 'jquery';
//import $ from 'jquery';
import { Helmet } from "react-helmet";

//import axios from "axios";
//import { Nav, NavLink, NavMenu }     from "./HeaderElements";
import Topmenu from "./TopMenu";
window.$ = window.jQuery=jQuery;

const Header = () => {
    const [topmenus,getTopmenus,lang, setLang]=useState('');
         //alert(getLang);
    const getLang = sessionStorage.getItem('lang');
          document.body.dir = "ltr";
        sessionStorage.setItem('lang', 'en');   

function changesLanguage(event) {
    //let loc = "http://localhost:3000/about/";
    const qurl = window.location.href;
    const surl = qurl.split("?");
    //alert(event.target.getAttribute("value"));
    sessionStorage.setItem('lang', event.target.getAttribute("value"));
    //window.location.replace(surl[0] + "?lng=" + event.target.value);
    window.location.replace(surl[0]);
    
    setLang(event.target.getAttribute("value"));
	document.body.dir = event.target.getAttribute("dir");
  }

  return (<>
<Helmet><html lang="en" />
 <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/259a3fa7cff7218dc7df2786/script.js"></script>
<link href="/css/bootstrap.min.css" rel="stylesheet"></link><link href="/css/flexslider.css" rel="stylesheet"></link>
 <link href="/css/animation.css" rel="stylesheet"></link>
 <link href="/css/style.css" rel="stylesheet"></link>
 <link href="/css/font-awesome.min.css" rel="stylesheet"></link>
 <link rel="stylesheet"  type="text/css"  charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"/>
<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/>
<link href="https://code.jquery.com/ui/1.10.4/themes/ui-lightness/jquery-ui.css" rel="stylesheet"/>
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;700&display=swap" rel="stylesheet"/>
  </Helmet>
  
<header>
    <div className='container'>
      <div className="row">
        <div className="col-md-3">
          <a href="/" className="only-pc">
            <img src="/images/alizz-logo.png" className="header-logo" alt="Alizz"/>
          </a>
			<a href="/" className="only-mobile">
            <img src="/images/alizz-logo-mob.png" className="header-logo" alt="Alizz"/>
          </a>
          <nav className="navbar-light nvbar only-mobile">
		  {/*<a href="javascript:void();" value="ar" dir="rtl" className="langlink" onClick={changesLanguage}>عربي</a>*/}
		  <a href="/ar"  className="langlink">عربي</a>
		
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </nav>
        </div>
        <div className="col-md-5">
          <div className="header-menu">
		  <Topmenu/>
            
          </div>

        </div>
        <div className="col-md-4">
          <div className="langdiv">
		  {/*<a href="javascript:void();" value="ar" dir="rtl" className="langlink" onClick={changesLanguage}>عربي</a>*/}
		  <a href="/ar"  className="langlink">عربي</a>
<a href="https://alizzconnect.com/" className="loginlink" target="_blank"><span className="lt1">
<span>Internet Banking</span>
    
	 </span><span className="lt2">
<span>Login</span>
	 </span></a>
            <a href="#" className="searchlink"><img src="/images/search.png" alt=""/></a>
			 <div className="srchbox" id="srchbox">
			 <form action="/search" method="get">
			 <input type="text" name="s" />
			 <button id="sbtn">Search</button>
			 </form>
			 </div>
          </div>
        </div>
      </div>
    </div>
  </header></>
  );
};
  
export default Header;