import React,{useEffect,useState} from "react";
import jQuery from 'jquery';
//import $ from 'jquery';
import Footermenufirst from "./Footermenu1";
import Footermenusecond from "./Footermenu2";
import Footermenuthird from "./Footermenu3";
import Copyrightmenu from "./Copyrightmenu";

window.$ = window.jQuery=jQuery;
const Footer = () => {
    const [topmenus,getTopmenus,lang, setLang]=useState('');
         //alert(getLang);
    //const getLang = sessionStorage.getItem('lang');

    

       useEffect(()=>{
    //getAllTopmenus();
    },[]);
    
    /*const getAllTopmenus=()=>{
    axios.get(`${url}past`).then((response)=>{
        const allTopmenus=response.data.topmenus.allTopmenus;
        getTopmenus(allTopmenus);
    }).catch(error=>console.error(`Error:${error}`));
    }*/


  return (<>
<section className="section footsect">
    <div className="container  pb-4">
      <div className="row footer-menu">
        <div className="col-md-12">
          <button data-bs-toggle="collapse" data-bs-target="#footermenu" className="footer-menu-btn">
		  <img src="/images/down-arrow.png"/> 
<span>Sitemap</span>
		  </button>
          <div id="footermenu" className="collapse">
            <div className="d-inline-flex mx-4 mb-4 row">
              <div className="col-md-4">
			  <Footermenufirst/>
              </div>
              <div className="col-md-4">
                <Footermenusecond/>
              </div>
              <div className="col-md-4">
                <Footermenuthird/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="section copyrightsect">
    <div className="container  pb-5">
      <div className="row">
        <div className="col-md-2 rounded-nw5">
          <a href="https://www.facebook.com/alizzislamicbank" target="_blank"><img src="/images/facebook.svg" className="imglogo4"/></a>
          <a href="https://twitter.com/@alizz_islamic" target="_blank"><img src="/images/twitter.svg" className="imglogo4"/></a>
          <a href="https://www.instagram.com/alizz_islamic/" target="_blank"><img src="/images/instagram.svg" className="imglogo4"/></a>
          <a href="https://www.linkedin.com/company/3177741?trk=tyah&trkInfo=tas%3Aalizz" target="_blank"><img src="/images/linkedin.svg" className="imglogo4"/></a>
          <a href="https://www.youtube.com/channel/UCnPtIVVCvrT7_QEE_V_hyEA" target="_blank"><img src="/images/youtube.svg" className="imglogo4"/></a>

        </div>
        <div className="col-md-6 rounded-nw5">
          <h6 className="fn-sty3">
<span></span>
		 </h6>
          <a href="https://play.google.com/store/apps/details?id=com.alizzbank.android.mobile" target="_blank"><img src="/images/playstore.svg" className="imglogo4"/></a>
          <a href="https://appsto.re/om/iBCPQ.i" target="_blank"><img src="/images/ios.svg" className="imglogo4 apl" /></a>
          <a href="https://appgallery.huawei.com/#/app/C103087723" target="_blank"><img src="/images/huwai.svg" className="imglogo4"/></a>

        </div>
        <div className="col-md-4 fn-sty4">
          <h6 className="pt-2"> <Copyrightmenu/> <p>&copy; 
<span>Alizz Islamic Bank</span>
		  </p></h6>
        </div>
      </div>
    </div>
  </section>
  </>
  );
};
  
export default Footer;