import React, { useState, useEffect  } from 'react';
//import { graphql } from "@apollo/client/react/hoc";
//import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import parse from 'html-react-parser';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
/*const locales = {
  en: { 
  firstname:'First Name',
  lastname:'Last Name',
  mobile:'Mobile',
  email:'Email',
  wilayat:'Wilayat',
  iagree:'I Agree, check out our',
  privacypolicy:'Privacy Policy',
  apply:'Apply',
  applicationprocess:'Application Process',
  errorfirstname:'please enter first name',
  errorlastname:'please enter last name',
  errormobile:'please enter mobile number',
  erroremail:'please enter valid email',
  errorwilayat:'please enter wilayat',
  successsent:'Sent Successfully',
  select:'Select',
  applyfor:'Apply For',
  fillinformation:'Kindly fill in all the required information',
  financecalculator:'Finance Calculator',
  monthlyinstallment:'MONTHLY INSTALLMENT',
  amount:'Amount',
  tenor:'Tenor',
  estimationpurpose:'Calculator is for estimation purposes only',
  omr:'OMR',
  year:'Year',
  years:'Years',
  yearss:'Years',
  buyout:'Buyout',
  underconstruction:'Under Construction',
  readyproperty:'Ready Property',
  landfinance:'Land Finance',
  new:'New',
  used:'Used',
  salarytransfer:'Salary',
  pdc:'PDC'
  },
  ar: { 
  firstname:'الإسم',
  lastname:'القبيلة',
  mobile:'رقم الهاتف',
  email:'البريد الالكتروني',
  wilayat:'ولاية',
  iagree:'أوافق، اطلع على سياسة الخصوصية',
  privacypolicy:'سياسة العمل',
  apply:'تقديم الطلب',
  applicationprocess:'طريقة التقديم',
  errorfirstname:'please enter first name',
  errorlastname:'please enter last name',
  errormobile:'please enter mobile number',
  erroremail:'please enter valid email',
  errorwilayat:'please enter wilayat',
  successsent:'شكرا على ملاحظتك القيمة , سوف يتم الرد خلال يوم عمل واحد.',
  select:'اختر ',
  applyfor:'تقديم الطلب -',
  fillinformation:'الرجاء تعبئة جميع البيانات المطلوبة',
  financecalculator:'حاسبة التمويلات',
  monthlyinstallment:'القسط الشهري',
  amount:'المبلغ',
  tenor:'فترة السداد',
  estimationpurpose:'الحاسبة تقديرية فقط',
  omr:'ر.ع',
  year:'سنة',
  years:'سنوات',
  yearss:'سنوات/سنة',
  buyout:'تحويل التمويل من بنك آخر',
  underconstruction:'عقار قيد الإنشاء',
  readyproperty:'عقار جاهز',
  landfinance:'تمويل الأراضي',
  new:'سيارة جديدة',
  used:'سيارة مستعملة',
  salarytransfer:'تحويل الراتب',
  pdc:'شيكات آجلة'
  }
};*/
if(!sessionStorage.getItem('lang')){
	sessionStorage.setItem("lang", 'en');
}
var lgng= sessionStorage.getItem('lang');
//const langcontent=locales[lgng];
//console.log(langcontent);

	const cscript = document.createElement("script");
    cscript.src = "//cdnjs.cloudflare.com/ajax/libs/jqueryui/1.11.4/jquery-ui.min.js?ver=1.11.4";
    cscript.async = true;
    document.body.appendChild(cscript);
	
	const gscript = document.createElement("script");
    gscript.src = "//cdnjs.cloudflare.com/ajax/libs/jqueryui-touch-punch/0.2.3/jquery.ui.touch-punch.min.js?ver=0.2.3";
    gscript.async = true;
    document.body.appendChild(gscript);
	
class Financeslider extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
this.newusedToggle = this.newusedToggle.bind(this);
this.salarypdcToggle = this.salarypdcToggle.bind(this);
  }

state = {
        sliderdet: [],
		personalhead:'',
		firstname0: '',
		lastname0: '',
		mobile0: '',
		email0: '',
		wilayat0: '',
		remember0: '',
		vtype0: '',
		page: '',
		firstname1: '',
		lastname1: '',
		mobile1: '',
		email1: '',
		wilayat1: '',
		remember1: '',
		vtype1: '',
		firstname2: '',
		lastname2: '',
		mobile2: '',
		email2: '',
		wilayat2: '',
		remember2: '',
		vtype2: '',
		newused: 0,
		salarypdc: 0
      }
firstnamechange0 = event => {this.setState({ firstname0: event.target.value })}
 lastnamechange0 = event => {this.setState({ lastname0: event.target.value })}
 mobilechange0 = event => {this.setState({ mobile0: event.target.value })}
 emailchange0 = event => {this.setState({ email0: event.target.value })}
 wilayatchange0 = event => {this.setState({ wilayat0: event.target.value })}
 rememberchange0 = event => {this.setState({ remember0: event.target.value })}
 firstnamechange1 = event => {this.setState({ firstname1: event.target.value })}
 lastnamechange1 = event => {this.setState({ lastname1: event.target.value })}
 mobilechange1 = event => {this.setState({ mobile1: event.target.value })}
 emailchange1 = event => {this.setState({ email1: event.target.value })}
 wilayatchange1 = event => {this.setState({ wilayat1: event.target.value })}
 rememberchange1 = event => {this.setState({ remember1: event.target.value })}
 firstnamechange2 = event => {this.setState({ firstname2: event.target.value })}
 lastnamechange2 = event => {this.setState({ lastname2: event.target.value })}
 mobilechange2 = event => {this.setState({ mobile2: event.target.value })}
 emailchange2 = event => {this.setState({ email2: event.target.value })}
 wilayatchange2 = event => {this.setState({ wilayat2: event.target.value })}
 rememberchange2 = event => {this.setState({ remember2: event.target.value })}
 
  newusedToggle() {
	 if(this.state.newused==1){
    this.setState({ newused: 0 });
	 }else{
	this.setState({ newused: 1 });	 
	 }
	//console.log(this.state.newused)
  }
  
  salarypdcToggle() {
	if(this.state.salarypdc==1){
    this.setState({ salarypdc: 0 });
	 }else{
	this.setState({ salarypdc: 1 });	 
	 }
	//console.log(this.state.salarypdc)
  }
 
 onSubmit0 = e => {
        e.preventDefault();
		//const { name, value } = e.target;
		//let isError = { ...this.state.isError };
		//let isValid = false;
		
        if (this.state.firstname0=="") {
            //console.log(this.state.FirstName)
			//isError.firstname0 ="please enter the firstname";
			//isValid = false;
			//alert(this.state.firstname0);
		this.setState({
			errormsg0: 'First Name',
			succmessage0: null
  		});  	
        }else if(this.state.lastname0==""){
			//alert(this.state.lastname0);
			this.setState({
			errormsg0: 'Last Name',
			succmessage0: null
  		});  
		}else if(this.state.mobile0==""){
			//alert(9);
			this.setState({
			errormsg0: 'Mobile',
			succmessage0: null
  		});  


		}else if(this.state.email0==""){
			this.setState({
			errormsg0: 'Email',
			succmessage0: null
  		});  
		}else if(this.state.wilayat0==""){
			this.setState({
			errormsg0: 'Wilayat',
			succmessage0: null
  		});  
		}else{
			//const getLang = sessionStorage.getItem('lang');
const rst = { FirstName: this.state.firstname0, LastName: this.state.lastname0, Mobile: this.state.mobile0, Email: this.state.email0, Wilayat: this.state.wilayat0,Type: this.state.vtype0.value, Pages: this.state.page.value,locale:'en'}
const dta=JSON.stringify({data:rst});
			//alert(dta);
			
			Axios({
     method: "post",
     url: "https://alizzapi.alizzislamic.com/api/personalforms",
     data: dta,
     headers: { "Content-Type": "application/json; charset=utf-8" },
   })
      .then(res => {
        //console.log(res);
       // console.log(res.data);
		/*this.setState({
    succmessage0: 'Sent Successfully',
	errormsg0: null,
  });*/
  
  this.setState({
      lastname0: '',
      mobile0: '',
      email0: '',
      wilayat0: '',
      remember0: '',
    });
	document.getElementById("form0").reset();
      })
	  .catch(error => {
  this.setState({
    //message: error,
	errormsg0: error,
    succmessage0: null,
  });
  //console.log(error);
})
			
	var today = new Date(),
dateval = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
//console.log(dateval);
const apival = {
    "camp_chnl_prod_id": 161,
    "lms_camp_id": 78,
    "lms_chnl_id": 41,
    "camp_product_id": 204,
    "lead_name": this.state.firstname0+' '+this.state.lastname0,
    "dob": "1973-10-15T07:00:00.809179Z",
    "lead_mobile": this.state.mobile0,
    "lead_email": this.state.email0,
    "lead_description": "Muscat",
    "lead_currenbt_stage": 28,
    "status": "Y",
    "lead_crnt_phase_status": 2,
    "is_assigned": "N",
    "cur_oc_id": 41,
    "cur_gou_id": 4,
    "cur_ou_id": 7
}
	
	const apidta=JSON.stringify(apival);
Axios({
     method: "post",
     url: "https://alizzlms.alizzislamic.com/api/LMSLead",
     data: apidta,
     headers: { "Authorization": "Api Key","Content-Type": "application/json; charset=utf-8","ApiKey":"jm$lms_2023@lkjhqwer#$t67" },
   })
      .then(res => {
        console.log(res);
       // console.log(res.data);
		/*this.setState({
    succmessage0: 'Sent Successfully',
	errormsg0: null,
  });*/
  window.location.href ='/success';
      })
	  .catch(error => {
  /*this.setState({
    //message: error,
	errormsg0: error,
    succmessage0: null,
  });*/
  console.log(error);
})		
			
			/*this.setState({
			errormsg0: null,
			succmessage0: "Sent Successfully",
  		});*/ 
		 
			//alert(this.state.firstname0);
		}
    };
	
	
	onSubmit1 = e => {
        e.preventDefault();
        if (this.state.firstname1=="") {
			//alert(this.state.firstname1);
		this.setState({
			errormsg1: 'First Name',
			succmessage1: null
  		});  	
        }else if(this.state.lastname1==""){
			//alert(this.state.lastname0);
			this.setState({
			errormsg1: 'Last Name',
			succmessage1: null
  		});  
		}else if(this.state.mobile1==""){
			//alert(9);
			this.setState({
			errormsg1: 'Mobile',
			succmessage1: null
  		});  
		}else if(this.state.email1==""){
			this.setState({
			errormsg1: 'Email',
			succmessage1: null 
  		});  
		}else if(this.state.wilayat1==""){
			this.setState({
			errormsg1: 'Wilayat',
			succmessage1: null
  		}); 
		}else{
			
			//const getLang = sessionStorage.getItem('lang');
const rst = { FirstName: this.state.firstname1, LastName: this.state.lastname1, Mobile: this.state.mobile1, Email: this.state.email1, Wilayat: this.state.wilayat1,Type: this.state.vtype1.value, Page: this.state.page.value,locale:'en'}
const dta=JSON.stringify({data:rst});
			
			Axios({
     method: "post",
     url: "https://alizzapi.alizzislamic.com/api/personalforms",
     data: dta,
     headers: { "Content-Type": "application/json; charset=utf-8" },
   })
      .then(res => {
        //console.log(res);
       // console.log(res.data);
		/*this.setState({
    succmessage1: langcontent.successsent,
	errormsg1: null,
  });*/
  
  this.setState({
      lastname1: '',
      mobile1: '',
      email1: '',
      wilayat1: '',
      remember1: '',
    });
	document.getElementById("form1").reset();
      })
	  .catch(error => {
  this.setState({
    //message: error,
	errormsg1: error,
    succmessage1: null,
  });
  //console.log(error);
})
		var today = new Date(),
dateval = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
//console.log(dateval);
const apival = {
    "camp_chnl_prod_id": 161,
    "lms_camp_id": 78,
    "lms_chnl_id": 41,
    "camp_product_id": 204,
    "lead_name": this.state.firstname1+' '+this.state.lastname1,
    "dob": "1973-10-15T07:00:00.809179Z",
    "lead_mobile": this.state.mobile1,
    "lead_email": this.state.email1,
    "lead_description": "Muscat",
    "lead_currenbt_stage": 28,
    "status": "Y",
    "lead_crnt_phase_status": 2,
    "is_assigned": "N",
    "cur_oc_id": 41,
    "cur_gou_id": 4,
    "cur_ou_id": 7
}

const apidta=JSON.stringify(apival);
Axios({
     method: "post",
     url: "https://alizzlms.alizzislamic.com/api/LMSLead",
     data: apidta,
     headers: { "Authorization": "Api Key","Content-Type": "application/json; charset=utf-8","ApiKey":"jm$lms_2023@lkjhqwer#$t67" },
   })
      .then(res => {
        console.log(res);
       // console.log(res.data);
		/*this.setState({
    succmessage0: 'Sent Successfully',
	errormsg0: null,
  });*/
  window.location.href ='/success';
      })
	  .catch(error => {
  /*this.setState({
    //message: error,
	errormsg0: error,
    succmessage0: null,
  });*/
  console.log(error);
}) 
			//alert(this.state.firstname1);
		}
    };
	
	
	onSubmit2 = e => {
        e.preventDefault();
        if (this.state.firstname2=="") {
			//alert(this.state.firstname2);
		this.setState({
			errormsg2: 'First Name',
			succmessage2: null
  		});  	
        }else if(this.state.lastname2==""){
			//alert(this.state.lastname0);
			this.setState({
			errormsg2: 'Last Name',
			succmessage2: null
  		});  
		}else if(this.state.mobile2==""){
			//alert(9);
			this.setState({
			errormsg2: 'Mobile',
			succmessage2: null
  		});  
		}else if(this.state.email2==""){
			this.setState({
			errormsg2: 'Email',
			succmessage2: null
  		});  
		}else if(this.state.wilayat2==""){
			this.setState({
			errormsg2: 'Wilayat',
			succmessage2: null
  		});  
		}else{
			
			//const getLang = sessionStorage.getItem('lang');
const rst = { FirstName: this.state.firstname2, LastName: this.state.lastname2, Mobile: this.state.mobile2, Email: this.state.email2, Wilayat: this.state.wilayat2,Type: this.state.vtype2.value, Page: this.state.page.value,locale:'en'}
const dta=JSON.stringify({data:rst});
			//alert(dta);
			Axios({
     method: "post",
     url: "https://alizzapi.alizzislamic.com/api/personalforms",
     data: dta,
     headers: { "Content-Type": "application/json; charset=utf-8" },
   })
      .then(res => {
        //console.log(res);
       // console.log(res.data);
		/*this.setState({
    succmessage2: langcontent.successsent,
	errormsg2: null,
  });*/
  
  this.setState({
      lastname2: '',
      mobile2: '',
      email2: '',
      wilayat2: '',
      remember2: '',
    });
	document.getElementById("form2").reset();
      })
	  .catch(error => {
  this.setState({
    //message: error,
	errormsg2: error,
    succmessage2: null,
  });
  //console.log(error);
})

var today = new Date(),
dateval = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
//console.log(dateval);
const apival = {
    "camp_chnl_prod_id": 161,
    "lms_camp_id": 78,
    "lms_chnl_id": 41,
    "camp_product_id": 204,
    "lead_name": this.state.firstname2+' '+this.state.lastname2,
    "dob": "1973-10-15T07:00:00.809179Z",
    "lead_mobile": this.state.mobile2,
    "lead_email": this.state.email2,
    "lead_description": "Muscat",
    "lead_currenbt_stage": 28,
    "status": "Y",
    "lead_crnt_phase_status": 2,
    "is_assigned": "N",
    "cur_oc_id": 41,
    "cur_gou_id": 4,
    "cur_ou_id": 7
}

const apidta=JSON.stringify(apival);
Axios({
     method: "post",
     url: "https://alizzlms.alizzislamic.com/api/LMSLead",
     data: apidta,
     headers: { "Authorization": "Api Key","Content-Type": "application/json; charset=utf-8","ApiKey":"jm$lms_2023@lkjhqwer#$t67" },
   })
      .then(res => {
        console.log(res);
       // console.log(res.data);
		/*this.setState({
    succmessage0: 'Sent Successfully',
	errormsg0: null,
  });*/
  window.location.href ='/success';
      })
	  .catch(error => {
  /*this.setState({
    //message: error,
	errormsg0: error,
    succmessage0: null,
  });*/
  console.log(error);
})
		 
			//alert(this.state.firstname2);
		}
    };

      componentDidMount() {
	var lng= sessionStorage.getItem('lang');
	setTimeout(
    function() {
        $('#slider').cardSlider({
      slideTag: 'div'
      , slideClass: 'slide'
      , current: 1
      , followingClass: 'slider-content-6'
      , delay: 1000
      , transition: 'ease'
      , onBeforeMove: function (slider, onMove) {
        console.log('onBeforeMove')
        onMove()
      }
      , onMove: function (slider, onAfterMove) {
        onAfterMove()
      }
      , onAfterMove: function (index) {
        console.log('onAfterMove');
		//alert(index);
        var act=$(".center-slide .sldbox").attr("vl");
		$(".expntcnt").removeClass("active");
        $("#" + act).addClass("active");
		if ($(window).width() < 768){	
	setTimeout(function () {
                    $('html, body').animate({
        scrollTop: $('#'+act).offset().top - 120 //#DIV_ID is an example. Use the id of your destination on the page
    }, 'slow');
                 }, 2000);
		}else{
		$('html, body').animate({
        scrollTop: $('#'+act).offset().top - 120 //#DIV_ID is an example. Use the id of your destination on the page
    }, 'slow');	
		}
      }
      , onAfterTransition: function () {
        console.log('onAfterTransition')
      }
      , onCurrent: function () {
        console.log('onCurrent')
      }
    });
	
	 
				
				
				const fgl =  window.location.search.split('v=')[1];
//alert(fgl);
        $('html, body').animate({
        scrollTop: $('#'+fgl).offset().top - 120 //#DIV_ID is an example. Use the id of your destination on the page
    }, 'slow');
	
    }
    .bind(this),
    3500
);


setTimeout(
    function() {
	$("#amountvalautofinance").slider({
                  //range: true,
                  range: 'min',
                  min: 1000,
                  max: 30000,
                  //values: [ 100, 300 ],
                  slide: function (event, ui) {
                    //$("#amountdet").html($("#amountval").slider("values", 1));
                    //$("#amountdetautofinance").val($("#amountvalautofinance").slider("values", 1));
					$("#amountdetautofinance").val(ui.value);
					/*var amt=$("#amountdet0").val();
					var tnr=$("#tenordet0").val();
					var tval=amt/tnr;
					tval=tval.toFixed(2);
					$("#calcval0").html(tval);*/
					
var loanAmount = parseFloat($("#amountdetautofinance").val());
var tenor = parseInt($("#tenordetautofinance").val());
var isNewVehicle = parseInt($("#newusedval").val());
var isSalaryTransfer = parseInt($("#salarypdcval").val());

var interestRate;

if (isNewVehicle==1) {
    if (isSalaryTransfer==1) {
        if (tenor <= 3) {
            interestRate = 4.50 / 12 / 100; // Monthly interest rate for new vehicle and salary transfer, up to 3 years
        } else if (tenor <= 5) {
            interestRate = 5.00 / 12 / 100; // Monthly interest rate for new vehicle and salary transfer, 3+ to 5 years
        } else if (tenor <= 7) {
            interestRate = 5.50 / 12 / 100; // Monthly interest rate for new vehicle and salary transfer, 5+ to 7 years

        } else if (tenor <= 10) {
            interestRate = 5.75 / 12 / 100; // Monthly interest rate for new vehicle and salary transfer, 7+ to 10 years
        }
    } else { // PDC payment method
        interestRate = 6.00 / 12 / 100; // Monthly interest rate for new vehicle with PDC
    }
} else { // Used vehicle
    if (isSalaryTransfer==1) {
        interestRate = 5.75 / 12 / 100; // Monthly interest rate for used vehicle with Salary Transfer
    } else {
        interestRate = 6.00 / 12 / 100; // Monthly interest rate for used vehicle with PDC
    }
}

var numPayments = tenor * 12; // Total number of payments

// Calculate EMI using the formula
var emi = (loanAmount * interestRate * Math.pow(1 + interestRate, numPayments)) /
          (Math.pow(1 + interestRate, numPayments) - 1);

// Round the EMI value to three decimal places
emi = emi.toFixed(3);


$("#calcvalautofinance").html(emi);
					
					
                  }
                });
				 $("#tenorvalautofinance").slider({
                  //range: true,
                  range: 'min',
                  min: 1,
                  max: 10,
                  //values: [ 100, 300 ],
                  slide: function (event, ui) {
                    //$("#tenordet").html($("#tenorval").slider("values", 1));
                    //$("#tenordetautofinance").val($("#tenorvalautofinance").slider("values", 1));
					$("#tenordetautofinance").val(ui.value);
					/*var amt=$("#amountdet0").val();
					var tnr=$("#tenordet0").val();
					var tval=amt/tnr;
					tval=tval.toFixed(2);
					$("#calcval0").html(tval);*/
var loanAmount = parseFloat($("#amountdetautofinance").val());
var tenor = parseInt($("#tenordetautofinance").val());
var isNewVehicle = parseInt($("#newusedval").val());
var isSalaryTransfer = parseInt($("#salarypdcval").val());

var interestRate;

if (isNewVehicle==1) {
    if (isSalaryTransfer==1) {
        if (tenor <= 3) {
            interestRate = 4.50 / 12 / 100; // Monthly interest rate for new vehicle and salary transfer, up to 3 years
        } else if (tenor <= 5) {
            interestRate = 5.00 / 12 / 100; // Monthly interest rate for new vehicle and salary transfer, 3+ to 5 years
        } else if (tenor <= 7) {
            interestRate = 5.50 / 12 / 100; // Monthly interest rate for new vehicle and salary transfer, 5+ to 7 years
        } else if (tenor <= 10) {
            interestRate = 5.75 / 12 / 100; // Monthly interest rate for new vehicle and salary transfer, 7+ to 10 years
        }
    } else { // PDC payment method
        interestRate = 6.00 / 12 / 100; // Monthly interest rate for new vehicle with PDC
    }
} else { // Used vehicle
    if (isSalaryTransfer==1) {
        interestRate = 5.75 / 12 / 100; // Monthly interest rate for used vehicle with Salary Transfer
    } else {
        interestRate = 6.00 / 12 / 100; // Monthly interest rate for used vehicle with PDC
    }
}

var numPayments = tenor * 12; // Total number of payments

// Calculate EMI using the formula
var emi = (loanAmount * interestRate * Math.pow(1 + interestRate, numPayments)) /
          (Math.pow(1 + interestRate, numPayments) - 1);

// Round the EMI value to three decimal places
emi = emi.toFixed(3);


$("#calcvalautofinance").html(emi);
                  }
                });
				
				$("#amountvalpersonalfinance").slider({
                  //range: true,
                  range: 'min',
                  min: 1000,
                  max: 25000,
                  //values: [ 100, 300 ],
                  slide: function (event, ui) {
                    //$("#amountdet").html($("#amountval").slider("values", 1));
                    //$("#amountdetpersonalfinance").val($("#amountvalpersonalfinance").slider("values", 1));
					$("#amountdetpersonalfinance").val(ui.value);
					/*var amt=$("#amountdet1").val();
					var tnr=$("#tenordet1").val();
					var tval=amt/tnr;
					tval=tval.toFixed(2);
					$("#calcval1").html(tval);*/
					
					
					
					var loanAmount = parseFloat($("#amountdetpersonalfinance").val());
var tenor = parseInt($("#tenordetpersonalfinance").val());
var interestRate;

if (tenor <= 5) {
    interestRate = 5.25 / 12 / 100; // Monthly interest rate for 5.25% annual rate
} else if (tenor <= 7) {
    interestRate = 5.50 / 12 / 100; // Monthly interest rate for 5.50% annual rate
} else {
    interestRate = 6.00 / 12 / 100; // Monthly interest rate for 6.00% annual rate
}

var numPayments = tenor * 12; // Total number of payments

// Calculate EMI using the formula
var emi = (loanAmount * interestRate * Math.pow(1 + interestRate, numPayments)) /
          (Math.pow(1 + interestRate, numPayments) - 1);

// Round the EMI value to two decimal places
emi = emi.toFixed(3);

$("#calcvalpersonalfinance").html(emi);
					
                  }
                });
				 $("#tenorvalpersonalfinance").slider({
                  //range: true,
                  range: 'min',
				  step:1,
                  min: 1,
                  max: 10,
                  //values: [ 100, 300 ],
                  slide: function (event, ui) {
                    //$("#tenordet").html($("#tenorval").slider("values", 1));
                    //$("#tenordetpersonalfinance").val($("#tenorvalpersonalfinance").slider("values", 1));
					$("#tenordetpersonalfinance").val(ui.value);
					//alert($("#tenorvalpersonalfinance").slider("values", 4));
					/*var amt=$("#amountdet1").val();
					var tnr=$("#tenordet1").val();
					var tval=amt/tnr;
					tval=tval.toFixed(2);
					$("#calcval1").html(tval);*/
					
					
					
					var loanAmount = parseFloat($("#amountdetpersonalfinance").val());
var tenor = parseInt($("#tenordetpersonalfinance").val());
var interestRate;

if (tenor <= 5) {
    interestRate = 5.25 / 12 / 100; // Monthly interest rate for 5.25% annual rate
} else if (tenor <= 7) {
    interestRate = 5.50 / 12 / 100; // Monthly interest rate for 5.50% annual rate
} else {
    interestRate = 6.00 / 12 / 100; // Monthly interest rate for 6.00% annual rate
}

var numPayments = tenor * 12; // Total number of payments

// Calculate EMI using the formula
var emi = (loanAmount * interestRate * Math.pow(1 + interestRate, numPayments)) /
          (Math.pow(1 + interestRate, numPayments) - 1);

// Round the EMI value to two decimal places
emi = emi.toFixed(3);

$("#calcvalpersonalfinance").html(emi);
					
					
                  }
                });
				
				$(document).on('change', "#producttypedethomefinance", function() {
	//alert(5);
	//$("#amountvalhomefinance").slider("values",1000);
	$("#amountvalhomefinance").slider("min", "values", [1000, 10000]);
	$("#tenorvalhomefinance").slider("option", "values", [1, 10]);
	$("#amountdethomefinance").val(1000);
	$("#tenordethomefinance").val(1);
});	
				
				$("#amountvalhomefinance").slider({
                  //range: true,
                  range: 'min',
                  min: 1000,
                  max: 450000,
                  //values: [ 100, 300 ],
                  slide: function (event, ui) {
                    //$("#amountdet").html($("#amountval").slider("values", 1));
                    //$("#amountdethomefinance").val($("#amountvalhomefinance").slider("values", 1));
					$("#amountdethomefinance").val(ui.value);
					/*var amt=$("#amountdet2").val();
					var tnr=$("#tenordet2").val();
					var tval=amt/tnr;
					tval=tval.toFixed(2);
					$("#calcval2").html(tval);*/
					
					
					var loanAmount = parseFloat($("#amountdethomefinance").val());
var tenor = parseInt($("#tenordethomefinance").val());
var productType = $("#producttypedethomefinance").val();
//var paymentType = $("#salarypdcval").val(); // "Salary Transfer" or "PDC"
var isSalaryTransfer = parseInt($("#salarypdcval").val());
var interestRate;

//alert(loanAmount);

if (isSalaryTransfer === 1) {
    if (productType === "buyout") {
        interestRate = 4.75 / 12 / 100; // Monthly interest rate for Buyout
    } else if (productType === "readyHome") {
        interestRate = 5.00 / 12 / 100; // Monthly interest rate for Ready Home
    } else if (productType === "landFinance" || productType === "underConstruction") {
        interestRate = 5.25 / 12 / 100; // Monthly interest rate for Land Finance and Under-Construction
    }
} else{
    interestRate = 6.00 / 12 / 100; // Flat Monthly interest rate for PDC
}

var numPayments = tenor * 12; // Total number of payments

// Calculate EMI using the formula
var emi = (loanAmount * interestRate * Math.pow(1 + interestRate, numPayments)) /
          (Math.pow(1 + interestRate, numPayments) - 1);

// Round the EMI value to three decimal places
emi = emi.toFixed(3);
					
					
					

$("#calcvalhomefinance").html(emi);
					
					
                  }
                });
				 $("#tenorvalhomefinance").slider({
                  //range: true,
                  range: 'min',
                  min: 1,
                  max: 20,
                  //values: [ 100, 300 ],
                  slide: function (event, ui) {
                    //$("#tenordet").html($("#tenorval").slider("values", 1));
                    //$("#tenordethomefinance").val($("#tenorvalhomefinance").slider("values", 1));
					$("#tenordethomefinance").val(ui.value);
					/*var amt=$("#amountdet2").val();
					var tnr=$("#tenordet2").val();
					var tval=amt/tnr;
					tval=tval.toFixed(2);
					$("#calcval2").html(tval);*/
					var loanAmount = parseFloat($("#amountdethomefinance").val());
var tenor = parseInt($("#tenordethomefinance").val());
var productType = $("#producttypedethomefinance").val();
//var paymentType = $("#salarypdcval").val(); // "Salary Transfer" or "PDC"
var isSalaryTransfer = parseInt($("#salarypdcval").val());
var interestRate;

if (isSalaryTransfer === 1) {
    if (productType === "buyout") {
        interestRate = 4.75 / 12 / 100; // Monthly interest rate for Buyout
    } else if (productType === "readyHome") {
        interestRate = 5.00 / 12 / 100; // Monthly interest rate for Ready Home
    } else if (productType === "landFinance" || productType === "underConstruction") {
        interestRate = 5.25 / 12 / 100; // Monthly interest rate for Land Finance and Under-Construction
    }
} else{
    interestRate = 6.00 / 12 / 100; // Flat Monthly interest rate for PDC
}

var numPayments = tenor * 12; // Total number of payments

// Calculate EMI using the formula
var emi = (loanAmount * interestRate * Math.pow(1 + interestRate, numPayments)) /
          (Math.pow(1 + interestRate, numPayments) - 1);

// Round the EMI value to three decimal places
emi = emi.toFixed(3);

$("#calcvalhomefinance").html(emi);
                  }
                });
							
}
    .bind(this),
    3000
);




	Axios.get("https://alizzapi.alizzislamic.com/api/finance?locale=en&populate[0]=BankDetails.Icon&populate[1]=BankDetails.ApplicationProcess.Icon,BankDetails.Tabs.Icon,BankDetails.CalculatorSection")
        //Axios.get('./menu.json')
          .then(res => {
             const sliderdet = res.data.data.attributes.BankDetails;
			const personalhead = res.data.data.attributes.PersonalHead;
			this.setState({ sliderdet,personalhead });
            //console.log(sliderdet);
          });
//alert(4);

      
        
      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
    } 
      render() {
    //console.log(this.props);
	var asr=[];
	asr=this.state.sliderdet;
	//console.log(this.state.sliderdet);
	//let hash = window.location.hash.substring(1);
	const product =  window.location.search.split('v=')[1];
	//alert(product);

	var success = [];
     for(var i=0;i<asr.length;i++)
     {
        if(asr[i]['slug'] ==product)
        {
         success[0] = [asr[i]];
        }
     }
	 
	 var successw = [];
     for(var i=0;i<asr.length;i++)
     {
        if(asr[i]['slug'] !=product)
        {
         successw[i] = [asr[i]];
        }
     }
	 var array1=[];
	 array1 = success.concat(successw);
	 array1.sort();
	console.log(array1);
	
	//console.log(hash);
	var pth='https://alizzapi.alizzislamic.com/';
    return (
	<>
      <div className="slidertab">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="slidtabhead">{this.state.personalhead}</p>
            <div id="slider" className="ui-card-slider">
			{/*this.displaySlider()*/}
	  {           
          array1.map( (prdtl, index) =>
            {	
			var dd="dd";
return(<><div key={prdtl[0].id} className="slide">
                <div className="sldbox" vl={dd+index}  onClick={this.handleClickEvent.bind(this, index)}>
                  <h2>{prdtl[0].Title}</h2>
				   {(() => {
        if(prdtl[0].IconHtml!=null){
				return (<><span>{parse(prdtl[0].IconHtml)}</span></>); 
        }else{
	{(() => {
        if(prdtl[0].Icon.data!=null){
	}
    })()}
			return (<><img src={pth + prdtl[0].Icon.data.attributes.url} alt={prdtl[0].Title} className="sldimg"/></>);

		}
    })()}
                  <div className="sldcnt">
                    <h5>{prdtl[0].Subtitle}</h5>
                    <p>{parse(prdtl[0].ShortText)}</p>
                  </div>
                  <a type="submit" className="btn btn-primary">Select</a>
                </div>
              </div></>)
          })
        }
	   </div>
          </div>
        </div>
      </div>
    </div>
	  {           
          array1.map( (prdt2, index) =>
            {	
			var dd="dd";
			//console.log(prdt2);
			var ml=index;
			var hh="form";
return(<>
<div key={prdt2[0].id} id={dd+index}  className={`expntcnt ${index==0 ? "active" : ""}`}>

        <section className="calc-inner">
        <div className="container pb-5" id={prdt2[0].slug}>
          <div className="row  d-flex justify-content-center">
		  {(() => {
            if(prdt2[0].Calculator!=null && prdt2[0].Calculator!=false && prdt2[0].CalculatorSection!=null && prdt2[0].CalculatorSection!=false ){
				var amtdt="amountdet";
				var tnrdt="tenordet";
				var amtvl="amountval";
				var tnrvl="tenorval";
				var clvl="calcval";
		  return (<><div className="col-md-7">
              <h4 className="text-center">Finance Calculator</h4>
              
			  
			  
			   {(() => {
            if(prdt2[0].CalculatorSwitch!=null && prdt2[0].CalculatorSwitch!=false ){
		  return (<>	
                <div className="calcscroll">
			  <div className="form-check form-switch">
<table><tr><td></td><td>
{(() => {
            if(prdt2[0].slug!="homefinance" ){
		  return (<>
				<BootstrapSwitchButton
    onlabel='New'
    offlabel='Used'
	checked={this.state.newused}
    onChange={this.newusedToggle}
/>
				</>); 
        }
    })()}

<BootstrapSwitchButton
    onlabel='Salary'
    offlabel='PDC'
	checked={this.state.salarypdc}
    onChange={this.salarypdcToggle}
/>
{(() => {
            if(prdt2[0].slug=="homefinance" ){
		  return (<>
				<select name="producttypedethomefinance" id="producttypedethomefinance">
				<option value="readyHome">Ready Property</option>
				<option value="underConstruction">Under Construction</option>
				<option value="landFinance">Land Finance</option>
				<option value="buyout">Buyout</option>
				</select>
				</>); 
        }
    })()}

<input id="newusedval" name="newusedval" type="hidden" value={this.state.newused} />
<input id="salarypdcval" name="salarypdcval" type="hidden" value={this.state.salarypdc} />
{/*<input className="form-check-input" type="checkbox" role="switch" id="personalswitch" checked/>*/}</td><td></td></tr></table></div>
			  
</div>
				</>); 
        }
    })()}
			  
			  
			  
              <div className="calcscroll">
                <p className="amvl">Amount: 
                  <input type="text" name="amountdet" id={amtdt+prdt2[0].slug} value="1000"
                    className="form-control amtcls"/> &nbsp;OMR
                </p>
                <div className="rangebg">
                  <div id={amtvl+prdt2[0].slug}></div>
                </div>
                <div className="rngedt"><span>
				{(() => {
            if(prdt2[0].CalculatorSection!=null && prdt2[0].CalculatorSection!=false ){
		  return (<>
				{prdt2[0].CalculatorSection.MinAmount} 
				</>); 
        }
    })()}
				&nbsp;OMR</span><span>
				{(() => {
            if(prdt2[0].CalculatorSection!=null && prdt2[0].CalculatorSection!=false ){
		  return (<>
				{prdt2[0].CalculatorSection.MaxAmount} 
				</>); 
        }
    })()}
				 &nbsp;OMR</span></div>
                <p className="amvl">Tenor:
                  <input type="text" name="tenordet" id={tnrdt+prdt2[0].slug} value="1"
                    className="form-control tnrcls"/> Years
                </p>
                <div className="rangebg">
                  <div id={tnrvl+prdt2[0].slug}></div>
                </div>
                <div className="rngedt"><span>{prdt2[0].CalculatorSection.MinTenure} Year</span><span>{prdt2[0].CalculatorSection.MaxTenure} Years</span></div>
				<div className="calcdetail">
                <p className="ctitle">MONTHLY INSTALLMENT</p>
                <p className="cval"><font id={clvl+prdt2[0].slug}>86.066</font> <span>OMR</span></p>
              </div>

                <p className="cnote">* Calculator is for estimation purposes only</p>
              </div>
            </div></>); 
        }
    })()}
		  
{(() => {
            if(prdt2[0].FormDisplay!=null && prdt2[0].FormDisplay!=false ){
				if(index==0){
		var ind=this.onSubmit0;
		var err=this.state.errormsg0;
		var fnm=this.firstnamechange0;
		var lst=this.lastnamechange0;
		var mbl=this.mobilechange0;
		var eml=this.emailchange0;
		var wil=this.wilayatchange0;
		var succ=this.state.succmessage0;
		//var hd=this.state.vtype0;
	}
	if(index==1){
		var ind=this.onSubmit1;
		var err=this.state.errormsg1;
		var fnm=this.firstnamechange1;
		var lst=this.lastnamechange1;
		var mbl=this.mobilechange1;
		var eml=this.emailchange1;
		var wil=this.wilayatchange1;
		var succ=this.state.succmessage1;
		//var hd=this.state.vtype1;
	}
	if(index==2){
		var ind=this.onSubmit2;
		var err=this.state.errormsg2;
		var fnm=this.firstnamechange2;
		var lst=this.lastnamechange2;
		var mbl=this.mobilechange2;
		var eml=this.emailchange2;
		var wil=this.wilayatchange2;
		var succ=this.state.succmessage2;
		//var hd=this.state.vtype2;
	}
	return (<><div className="col-md-5">
              <h4 className="text-center">Apply For {prdt2[0].Title}</h4>
              <p> Kindly fill in all the required information </p>
              <div>
			  {(() => {
        if(err!=null){
	return (
                <>
				<p className="alert alert-danger">{err}</p>
                </>
                );
}
})()}
{(() => {
        if(succ!=null){
	return (
                <>
				<p className="alert alert-success">{succ}</p>
                </>
                );
}
})()}
                <form  onSubmit={ind} className="finfrm" id={hh+index}>
<div className="errnull"></div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`firstname${index}`}>First Name</label>
                    <input type="text" className="form-control" id={`firstname${index}`} placeholder="" name={`firstname${index}`} onChange={fnm} />

                  </div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`lastname${index}`}>Last Name</label>
                    <input type="text" className="form-control" id={`lastname${index}`} placeholder="" name={`lastname${index}`} onChange={lst}/>
                  </div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`mobile${index}`}>Mobile</label>
                    <input type="text" className="form-control" id={`mobile${index}`} placeholder="" name={`mobile${index}`} onChange={mbl}/>
                  </div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`email${index}`}>Email</label>
                    <input type="email" className="form-control" id={`email${index}`} placeholder="" name={`email${index}`} onChange={eml}/>
                  </div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`wilayat${index}`}>Wilayat</label>
                    <input type="text" className="form-control" id={`wilayat${index}`} placeholder="" name={`wilayat${index}`} onChange={wil}/>
                  </div>
                  <div className="form-check mb-3 form-group">
                    <label className="form-check-label">
                      <input className="form-check-input prvchck" type="checkbox" name={`remember${index}`}  id="prvchck" /> I Agree, check out our <a
                        href="/privacy-policy">Privacy Policy</a>
                    </label>
                  </div>
				 
				 
				  {(() => {
        if(index==0){
	return (
                <>
				 <input type="hidden" value={prdt2[0].Title} name={`vtype${index}` } ref={(input) => { this.state.vtype0 = input }}/>
                </>
                );
}
if(index==1){
	return (
                <>
				 <input type="hidden" value={prdt2[0].Title} name={`vtype${index}` } ref={(input) => { this.state.vtype1 = input }}/>
                </>
                );
}
if(index==2){
	return (
                <>
				 <input type="hidden" value={prdt2[0].Title} name={`vtype${index}` } ref={(input) => { this.state.vtype2 = input }}/>
                </>
                );
}
})()}
				  <input type="hidden" value='Finance' name='page' ref={(input) => { this.state.page = input }}/>
                  <button type="submit" className="btn btn-primary sty1 mx-2" id="apbtn" disabled="disabled">Apply</button>
                </form>

              </div>
            </div></>); 
        }
    })()}

          </div>
        </div>
      </section>



<section className="quick-link-inner">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-12 text-center pb-4">
              <p className="slidtabhead"> Application Process </p>
            </div>
          </div>
          <div className="row text-center px-5 d-flex">
		   {prdt2[0].ApplicationProcess.map((post, index) => {
			   return (
				<>
        <div className="col-md-4">
		  {(() => {
        if(post.IconHtml!=null){
				return (<><span>{parse(post.IconHtml)}</span></>); 
        }else{
			return (<><img src={pth + post.Icon.data.attributes.url} alt={post.Title} className="content-iconset"/></>);
		}
    })()}
          <h4>{post.Title}</h4>
		  <p>{parse(post.Details)}</p>
        </div>
		</>
			   )
			})}

          </div>
        </div>
      </section>
	  
	  
	  <section className="tab-inner">
        <div className="container mt-3">
          <ul className="nav nav-tabs justify-content-end" role="tablist">
		  {prdt2[0].Tabs.map((post, index) => {
			  var gg="#gg";
			   return (
				<>
            <li className="nav-item">
              <a className={`nav-link ${index==0 ? "active" : ""}`} data-bs-toggle="tab" href={gg+ml+index}>{post.Title}</a>
            </li>
			</>
			   )
			})}
          </ul>
		  
		  <div className="tab-content mb-5">
		  {prdt2[0].Tabs.map((post, index) => {
			  var gg="gg";
			  var acc="accordion";
			   return (
				<>
				 <div id={gg+ml+index} className={`tbpnl tab-pane ${index==0 ? "active" : ""}`}>
				 <div className="tnpnlinr mt-3">
				 <div className="aoaccordion" id={acc+ml+index}>
				 {parse(post.Content)}
				 </div>
				 </div>
					</div>
			</>
			   )
           })}
		  </div>
		  </div>
		  </section>


</div>
</>)
          })
        }

	  </>
	  
    );
  }
    }


export default Financeslider;
    