//Make the necessary imports
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
//import { ShimmerCategoryItem } from "react-shimmer-effects";
//import SlickSlider from "./Slider";

//GraphQL query to fetch all the blogs from the backend
/*const locales = {
  en: { 
  whatwouldyou:'What would you like to do today?',
  },
  ar: { 
  whatwouldyou:'ماذا تريد ان تفعل اليوم؟',
  }
};
var lgng= sessionStorage.getItem('lang');
const langcontent=locales[lgng];*/

export const HOMETABS = gql`
{
    home {
      data {
        id
        attributes {
          localizations{
            data{
              attributes{
                AccountDetails{
                  id
                  Title
                  AccountExtra{
                    Title
                    Content
                    Link
                    LinkText
                    Icon{
                      data{
                        attributes{
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
            AccountDetails{
              id
              Title
              AccountExtra{
                id
                Title
                Content
                Link
                LinkText
                Icon{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              
              }
              }
            }
          }
          }
`

export default function Homepage() {
	var pth='https://alizzapi.alizzislamic.com';
	var actnm='tb';
const { loading, error, data } = useQuery(HOMETABS);
console.log(data);

//if (loading) return <div className="container"><ShimmerCategoryItem /></div>
if (loading) return <p></p>
if (error) return <p>Error :(</p>
const getLang = sessionStorage.getItem('lang');
//console.log(data.home.data.attributes.AccountDetails);
  return (
  <>
  <section className="section whatyousect">
    <div className="container pt-3 pb-5">
      <div className="row">
        <div className="col-md-12 text-center mb-2">
          <h3>
<span>What would you like to do today?</span>
		  </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ul className="customertab mt-4 mb-5" id="customertab">
				  {data.home.data.attributes.AccountDetails.map((post, index) => {
   return (
    <>
      <li  key={post.id} className={`${index==0 ? "active" : ""}`} vl={actnm+post.id}>{post.Title}</li>
    </>
   )
})}
          </ul>
        </div>
      </div>


{data.home.data.attributes.AccountDetails.map((post, index) => {
	//console.log(post.AccountExtra);
   return (
    <>
      <div key={post.id} className={`justify-content-center contfdiv ${index==0 ? "active" : ""}`} id={actnm+post.id}>
        <div className="row boxoutersty">
		{post.AccountExtra.map((ipost, iindex) => {
			//console.log(ipost);
			
			
			//var eventlist = JSON.stringify(ipost.Content);//Jsonresult

//var eventstring = new String();

//eventstring = eventlist.toString().replace("\"","");
//console.log(eventstring);
          return (
    <>
	<div className="col-md-4">
            <div className="d-flex justify-content-center">
              <span className="rounded-circle">
				<img src={pth + ipost.Icon.data.attributes.url} alt={ipost.Title} className="img-fluid imglogo"/>

              </span>
            </div>
            <span className="rounded-nw border">
              <div className="text-center">
                <h3 className="hd-ty1">{ipost.Title}</h3>
              </div>
				  {parse(ipost.Content)}
              <div className="text-center">
                <a href={ipost.Link} className="linkh btn btn-primary">
                  {ipost.LinkText}
                </a>
              </div>
            </span>

          </div>
		  </>
   )
})}



        </div>
      </div>
	  </>
   )
})}



    </div>
  </section>
  
	  </>
  );
}