import React, { useState, useEffect  } from 'react';
import { MenuComponent, } from '@syncfusion/ej2-react-navigations';
import { Link } from 'react-router-dom';
import Axios from 'axios';
const menuFields = {
    itemId: 'd',
    parentId: 'pid',
    text: 'text'
  };
export default class Footermenu1 extends React.Component {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    this.Lang = sessionStorage.getItem('lang');
    //this.storageChanged = this.storageChanged.bind(this);
  }
    state = {
        mainmenus: []
      }
      componentDidMount() {
        Axios.get(`https://alizzapi.alizzislamic.com/api/navigation/render/footer-menu-4?type=TREE&locale=en`)
        //Axios.get('./menu.json')
          .then(res => {
            const mainmenus = res.data;
            this.setState({ mainmenus });
            
          })
          
      }
      handleChangeDash (event)
        { 
          var attribute = event.target.attributes.getNamedItem('data-tag').value;    
          //alert(attribute);
          sessionStorage.setItem('pageid', attribute);
        }
        onLinkClick(e) {
          //e.preventDefault();
          // further processing happens here
          var attribute = e.target.attributes.getNamedItem('data-tag').value;  
          sessionStorage.setItem('pageid', attribute);
//console.log(attribute);
//window.location.reload();
       }
      render() {
        //console.log(this.state.mainmenus);
    return(
        <>{           
          this.state.mainmenus
            .map( (mainmenu, index) =>
            {	
              return(<>{/*<a  onClick={this.onLinkClick} className="nav-link" data-tag={mainmenu.id} href={mainmenu.path}>{mainmenu.title}</a>*/}<Link  key={mainmenu.id} to={mainmenu.path} className="cpymenu" data-tag={mainmenu.id} onClick={this.onLinkClick} >{mainmenu.title}</Link></>)
          }
            )
        }
</>)
      }
    }



    