//Make the necessary imports
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import { ShimmerCategoryItem } from "react-shimmer-effects";
//import SlickSlider from "./Slider";

//GraphQL query to fetch all the blogs from the backend
export const QUICKLINKS = gql`
{
    home {
      data {
        id
        attributes {
			localizations{
            data{
              attributes{
                HomeQuickLinks{
                  Title
                  Link
                  Icon{
                    data{
                      attributes{
                        url
                      }
                    }
                  }
                }
              }
            }
          }
            HomeQuickLinks{
              Title
              Link
              Icon{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              }
            }
          }
          }
`

export default function Homepage() {
	var pth='https://alizzapi.alizzislamic.com';
	
	const handleClick = (e) => {
    e.preventDefault();
    //console.log('The link was clicked.');
  	}
	
const { loading, error, data } = useQuery(QUICKLINKS);
//console.log(data);

//if (loading) return <div className="container"><ShimmerCategoryItem /></div>
if (loading) return <p></p>
if (error) return <p>Error :(</p>
//const getLang = sessionStorage.getItem('lang');
//console.log(data.home.data.attributes.QuickLinks);
  return (
  <>
  <section className="section quicklink2sect">
    <div className="container pb-5 pt-5">
      <div className="rounded-nw4">
        <div className="row rw-sty1">
          <div className="col-md-12">

            <div className="d-flex">
 {
            data.home.data.attributes.HomeQuickLinks.map(slide => (<div className="boxinnersty flex-fill" key={slide.id} >
                    <a href={slide.Link}>{/*<img src="images/Opan-an-account-white.svg" className="imglogo1"></br>*/}
					 <img src={pth + slide.Icon.data.attributes.url} alt={slide.Title} className="img-fluid imglogo1"/>
                      <h6 className="fn-sty2">{slide.Title}</h6>
                    </a>
                  </div>))
				  }
				
                </div>

          </div>
        </div>
      </div>
    </div>
  </section>
  
	  </>
  );
}