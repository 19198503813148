import React from 'react';
//import { graphql } from "@apollo/client/react/hoc";
//import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
//import { Link } from 'react-router-dom';
//import Slider, { slickGoTo } from "react-slick";
//import ReactCardSlider from 'react-card-slider-component';
//import parse from 'html-react-parser';
//import $ from 'jquery';
//import jQuery from 'jquery';
import Axios from 'axios';
//window.$ = window.jQuery = require('jquery')

	/*const cscript = document.createElement("script");
    cscript.src = "/js/cardslider.js";
    cscript.async = true;
    document.body.appendChild(cscript);*/
	
class Shariafatwacertificates extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }

state = {
        boddet: []
      }
      componentDidMount() {

	 //var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/shariafatwacertificate?locale=en&populate[0]=Certificates.Files")
        //Axios.get('./menu.json')
          .then(res => {
			const pagetitle = res.data.data.attributes.PageTitle;
            const sliderdet = res.data.data.attributes.Certificates;
			this.setState({ pagetitle,sliderdet });
            //console.log(sliderdet);
          });
//alert(4);

      
        
      }
 handleClickEvent(e, value) {
       // var myDomEl = this.state.context.find('#dd'+value);
    } 
      render() {
    //console.log(this.props);
	//var asr=[];
	//asr=this.state.pressdet.data;
	//console.log(this.state.boddet);
	//var asr=[];
	//asr=this.state.sliderdet;
	var pth='https://alizzapi.alizzislamic.com/';
	document.title = this.state.pagetitle;
	/*var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: false
  };*/
    return (
	<>
	
	<div className="bannerarea pb-4 pt-4">
    <div id="homebanner" className="bannerslider">

          <div className="container">
			<div className="row">
			<div className="col-md-12 bnrcntbox hnm">
                  <h3>{this.state.pagetitle}</h3>
              </div>
			</div>
          </div>

    </div>
	</div>
	
	<div className="termslist">
      <div className="container">
	  <div className="termsinr">
        <div className="row">
	  {           
          this.state.sliderdet?.map( (prdtl, index) =>
            {	
	return (<>
			<div className="col-md-4" key={prdtl.id}>
			<div className="termsbox">
					 <a href={pth + prdtl.Files.data.attributes.url} target="_blank" rel="noreferrer"><h4>{prdtl.Title}</h4></a>
					</div>
			</div>
	</>);
          })
        }
</div></div>




</div></div>



	  </>
	  
    );
  }
    }


export default Shariafatwacertificates;
    