import React, { useState, useEffect,useRef  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import Slider, { slickGoTo } from "react-slick";
//import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);*/
	
class Financials extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }

state = {
        sliderdet: []
      }

      componentDidMount() {
	
	var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/financial?locale=en&populate[0]=BannerImage,AnnualReport.ReportFile,AnnualReport.CoverImage&populate[2]=QuarterlyReports.Reports.ReportFile,QuarterlyReports.AnnualCover,QuarterlyReports.AnnualReport")
        //Axios.get('./menu.json')
          .then(res => {
			const pagetitle = res.data.data.attributes.Title;
            const sliderdet = res.data.data.attributes.BannerImage.data.attributes.url;
			const annualreportdet = res.data.data.attributes.AnnualReport;
			const quarterlyreportdet = res.data.data.attributes.QuarterlyReports;
			this.setState({ pagetitle,sliderdet,annualreportdet,quarterlyreportdet });
            //console.log(sliderdet);
          });
//alert(4);

      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
		//alert(e);
		//$("#dslide").current.slickGoTo(e);
		//$('#dslide').slick('slickGoTo', e); 
    } 
      render() {
   //console.log(this.state.quarterlyreportdet);
	var asr=[];
	asr=this.state.sliderdet;
	var qrtldt=[];
	qrtldt=this.state.quarterlyreportdet;
	var anldt=[];
	anldt=this.state.annualreportdet;
	
	
var actnm='s';
	var pth='https://alizzapi.alizzislamic.com';
	document.title = this.state.pagetitle;
	var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: false
  };
  
  
  var rsettings = {
    dots: false,
	//fade: true,
    infinite: true,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  };
  //console.log(qrtldt);
    return (
	
	<>
	
	 <div className="bannerarea pb-4 pt-4">
    <div id="homebanner" className="bannerslider">

          <div className="container">
			
			<div className="row">
			<div className="col-md-12 bnrcntbox hnm">
                  <h3>{this.state.pagetitle}</h3>
              </div>
			</div>
			
          </div>

    </div>
	</div>
	
	
	
	
	<div className="reportslist pb-4 pt-4">
    <div id="reportdiv" className="reportslider">

          <div className="container">
		  <Slider {...rsettings}>
            {           
          qrtldt?.map( (qrpt,index) =>
            {	
			return(<>
				<div className="reportcol">
				<h4>{qrpt.Title}</h4>
				{           
		          qrpt.Reports?.map( (qrptt,index) =>
		            {	
						return(<><div className="reportrow"><a href={pth + qrptt.ReportFile.data.attributes.url} target="_blank">{qrptt.Title}</a></div></>)
		            }
		            )
        		}

			{(() => {
        if(qrpt.AnnualReport.data!=null && qrpt.AnnualCover.data!=null){
              return (<><a href={pth + qrpt.AnnualReport.data.attributes.url} target="_blank" className="anlrpt"><img src={pth + qrpt.AnnualCover.data.attributes.url}  className=" img-fluid"/><h5>Annual Report {qrpt.Title}</h5></a></>); 
        }
    })()}
              </div></>)
          	}
            )
        	}             
			</Slider>
          </div>

    </div>
	</div>
	
	

	  </>
	  
    );
  }
    }


export default Financials;
    