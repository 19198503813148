import React from 'react';
//import { Link } from 'react-router-dom';
import Axios from 'axios';
export default class Personal extends React.Component {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }
    state = {
        personaldetail: [],
		introdetail: [],
		introbox: []
      }
      componentDidMount() {
        Axios.get(`https://alizzapi.alizzislamic.com/api/personal?locale=en&populate=*`)
        //Axios.get('./menu.json')
          .then(res => {
            const personaldetail = res.data.data.attributes.Getintouch;
			const introdetail = res.data.data.attributes.IntroDetails;
			const introbox = res.data.data.attributes.Introbox;
            this.setState({ personaldetail,introdetail,introbox });
            //console.log(res.data.data.attributes);
          })
          
      }
      render() {
		//console.log(this.state.personaldetail.Getintouch);
        
    return(
        
        <nav class="top-navigation clearfix">
        <ul>
{           
          this.state.personaldetail
            .map( (prdtl, index) =>
            {	
return(
              <li  key={prdtl.id}  class="tplevel1">
                <a  onClick={this.onLinkClick} class="firstLevel" data-tag={prdtl.id}>{prdtl.Title}</a>
                {/*<Link to={mainmenu.url} class="firstLevel" data-tag={mainmenu.pageid} onClick={this.handleChangeDash} >{mainmenu.text}</Link>*/}
</li>
)
          }
            )
        }
      </ul>


<ul>
{           
          this.state.introdetail.Title
            
        }
      </ul>


<ul>
{           
          this.state.introbox
            .map( (ibdtl, index) =>
            {	
return(
              <li  key={ibdtl.id}  class="tplevel1">
                <a  onClick={this.onLinkClick} class="firstLevel" data-tag={ibdtl.id}>{ibdtl.Title}</a>
                {/*<Link to={mainmenu.url} class="firstLevel" data-tag={mainmenu.pageid} onClick={this.handleChangeDash} >{mainmenu.text}</Link>*/}
</li>
)
          }
            )
        }
      </ul>
    </nav>)
      }
    }



    