//Make the necessary imports
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import SlickSlider from "./Slider";

//GraphQL query to fetch all the blogs from the backend
export const QUICKLINKS = gql`
{
    account {
      data {
        id
        attributes {
          localizations{
            data{
              attributes{
                FixedQuickLinks{
                  Title
                  Link
                  Icon{
                    data{
                      attributes{
                        url
                      }
                    }
                  }
                }
              }
            }
          }
            FixedQuickLinks{
              Title
              Link
              Icon{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              }
            }
          }
          }
`

export default function FixedQuicklinks() {
	var pth='https://alizzapi.alizzislamic.com/';
	var pffth='r';
	const handleClick = (e) => {
    e.preventDefault();
    console.log('The link was clicked.');
  	}
	
const { loading, error, data } = useQuery(QUICKLINKS);
//console.log(data);

if (loading) return <p>Loading...</p>

if (error) return <p>Error :(</p>
const getLang = sessionStorage.getItem('lang');
//console.log(data.home.data.attributes.QuickLinks);
  return (
  <>
  <section className="section quicklinksect">
    <div className="container pt-4 pb-3">
      <div className="row text-center">
        <div className="flex-row">
          <div className="col-md-12 flex-item">
            <div className="grid-main">
              <div className="d-inline-flex boxinnersty2">
                <div className="d-inline-flex">
                  <a href="javascript:void(0);" id="pclose"  className="pclose"><i className="fa fa-close"></i></a>
 {
            data.account.data.attributes.FixedQuickLinks.map((ql, i) => (<div className="boxinnersty3 border-end" key={ql+i} >
                    <a href={ql.Link}>{/*<img src="images/Opan-an-account-white.svg" className="imglogo1"></br>*/}
					 <img src={pth + ql.Icon.data.attributes.url} alt={ql.Title} className="img-fluid imglogo1"/>
                      <h6 className="fn-sty2">{ql.Title}</h6>
                    </a>
                  </div>))
				  }
				
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
	  </>
  );
}