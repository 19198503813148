import React, { useState, useEffect } from "react";
import './App.css';
import $ from 'jquery';
import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { ReactComponent as Logo } from './alizz-logo.png';

//import 'flexslider';
import Header from "./components/Header";
import Homepage from "./pages/Homepage";
import BlogDetails from "./pages/BlogDetails";
import PersonalDetails from "./pages/PersonalDetails";
import Accounts from "./pages/Accounts";
import Finance from "./pages/Finance";
import Takaful from "./pages/Takaful";
import Page from "./pages/Page";
import Pagear from "./pages/Pagear";
import Careers from "./pages/Careers";
import CareerForm from "./pages/CareerForm";
import Careerpage from "./pages/Careerpage";
import Branchlocations from "./pages/Branchlocations";
import Customerexperience from "./pages/Customerexperience";
import Footer from "./components/Footer";
import Financials from "./pages/Financials";
import Pressrelease from "./pages/Pressrelease";
import Creditcard from "./pages/Creditcard";
import Business from "./pages/Business";
import Digital from "./pages/Digital";
import BOD from "./pages/BOD";
import RegulatoryDisclosure from "./pages/RegulatoryDisclosure";
import Termsconditions from "./pages/Termsconditions";
import Historicalprofitrates from "./pages/Historicalprofitrates";
import Tharwa from "./pages/Tharwa";
import Keyfactstatements from "./pages/Keyfactstatements";
import Shariafatwacertificates from "./pages/Shariafatwacertificates";
import Success from "./pages/Success";
import Businessservices from "./pages/Businessservices";
import Alizzjunior from "./pages/Alizzjunior";
import Alizzone from "./pages/Alizzone";
import Homefinance from "./pages/Homefinance";
import Autofinance from "./pages/Autofinance";
import Iban from "./pages/Iban";
import Iban2 from "./pages/Iban2";
import Axios from 'axios';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"

import {
  BrowserRouter,
  Routes,
  Route,
  useParams
} from "react-router-dom";

import {Helmet} from "react-helmet";

// initialize apollo client
const client = new ApolloClient({
  uri: 'https://alizzapi.alizzislamic.com/graphql',
  //uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
	//uri: 'http://localhost:1337/graphql',
  cache: new InMemoryCache()
})


function App() {
	const [items, setItems,lang, setLang] = useState([]);
    const getLang = sessionStorage.getItem('lang');
//console.log(getLang);
  return (
    <BrowserRouter>
	<Helmet>
	</Helmet>
      <ApolloProvider client={client}>
            <Header />
            <Routes>
              <Route path="/" element={<Homepage />} />   
			  {(() => {
        		if(getLang=="ar"){
			   return (<><Route path="/:slug" element={<Pagear />} /></>);
			  }else{
				return (<><Route path="/:slug" element={<Page />} /></>);  
			 }
    			})()}
			{(() => {
        		if(getLang=="ar"){
			   return (<><Route path="/:slug/:slug" element={<Pagear />} /></>);
			  }else{
				return (<><Route path="/:slug/:slug" element={<Page />} /></>);  
			 }
    			})()}

			  {(() => {
        		if(getLang=="ar"){
			   return (<><Route path="/:slug/:slug/:slug" element={<Pagear />} /></>);
			  }else{
				return (<><Route path="/:slug/:slug/:slug" element={<Page />} /></>);  
			 }
    			})()}
			  <Route path="/personal" element={<PersonalDetails />} />
			  <Route path="/personal/accounts" element={<Accounts />} />
			  <Route path="/personal/finance" element={<Finance />} />
			  <Route path="/personal/takaful" element={<Takaful />} />
			  <Route path="/the-bank/contact-us/branch-locations" element={<Branchlocations />} />
			  <Route path="/job-openings" element={<Careers />} />
			  <Route path="/applynow/:id" element={<CareerForm />} />
			  <Route path="the-bank/contact-us/careers" element={<Careerpage />} />
			  <Route path="/the-bank/contact-us/customer-experience-inquiry" element={<Customerexperience />} />
			  <Route path="/the-bank/aboutus/financials" element={<Financials />} />
			  <Route path="/the-bank/media/press-releases" element={<Pressrelease />} />
				  <Route path="/the-bank/media/press-releases/:id" element={<BlogDetails />} />
				  <Route path="/personal/creditcard" element={<Creditcard />} />
				  <Route path="/business" element={<Business />} />
				  <Route path="/personal/digital" element={<Digital />} />
				  <Route path="/the-bank/aboutus/bod" element={<BOD />} />
				  <Route path="/the-bank/aboutus/regulatory-disclosure" element={<RegulatoryDisclosure />} />
				  <Route path="/personal/others/terms-conditions" element={<Termsconditions />} />
				  <Route path="/personal/others/historical-profit-rates" element={<Historicalprofitrates />} />
				  <Route path="/personal/tharwa" element={<Tharwa />} />
				  <Route path="/the-bank/contact-us/key-fact-statements" element={<Keyfactstatements />} />
				  <Route path="/the-bank/knowledge-centre/sharia-fatwa-certificates" element={<Shariafatwacertificates />} />
				  <Route path="/success" element={<Success />} />
				  <Route path="/business/alizz-business" element={<Businessservices />} />
				<Route path="/alizz-junior" element={<Alizzjunior />} />
				<Route path="/alizz-one" element={<Alizzone />} />
				<Route path="/home-finance" element={<Homefinance />} />
				<Route path="/auto-finance" element={<Autofinance />} />
				<Route path="/personal/iban" element={<Iban />} />
				<Route path="/iban2" element={<Iban2 />} />
            </Routes>
			<Footer />
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;