import React, { useState, useEffect  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
export const MAINTABS = gql`
{
    account {
      data {
id
        attributes {
            Slider {
			id
              Title
              Subtitle
              ShortText
              LinkText
              Link
              Icon{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              }
            }
          }
          }
`
	
class Accountslidercontent extends  React.Component  {
  constructor(props) {
    super(props);
    this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
  }

state = {
        sliderdet: []
      }
      componentDidMount() {

	 
	Axios.get(`https://alizzapi.alizzislamic.com/api/account?locale=en&populate=BankDetails.Icon`)
        //Axios.get('./menu.json')
          .then(res => {
            const sliderdet = res.data.data.attributes.BankDetails;
			this.setState({ sliderdet });
            //console.log(sliderdet);
          });
//alert(4);

      
        
      }

      render() {
    //console.log(this.props);
	//console.log(this.state.sliderdet);
	var pth='https://alizzapi.alizzislamic.com/';
    return (
	<>
			{/*this.displaySlider()*/}
	  {           
          this.state.sliderdet.map( (prdtl, index) =>
            {	
			var dd="dd";
return(<>
<div key={prdtl.id} id={dd+index}  className={`expntcnt ${index==0 ? "active" : ""}`}>
<h2>{prdtl.Title}</h2>
</div>
</>)
          })
        }


	  </>
	  
    );
  }
    }


export default Accountslidercontent;
    